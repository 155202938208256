import { Token } from '@pancakeswap/sdk'
// import { useMemo } from 'react'
// import { useAllTokenBalances } from '../../state/wallet/hooks'

const PINNED_SORTING = [
  'PLS',
  '9INCH',
  'BBC',
  'DAI',
  'USDC',
  'USDT',
  'WETH',
  'SOLIDX',
  'NOPE',
  'AXIS',
  'FIRE',
  'TEVE',
  'BRO',
  'BEAR',
  'ATROPA',
  'HEX',
]

function useTokenComparator(inverted: boolean): (tokenA: Token, tokenB: Token) => number {
  return (tokenA: Token, tokenB: Token) => {
    const pinnedOrderA = PINNED_SORTING.indexOf(tokenA.symbol?.toUpperCase())
    const pinnedOrderB = PINNED_SORTING.indexOf(tokenB.symbol?.toUpperCase())
    if(pinnedOrderA > -1 && pinnedOrderB > -1)
      return pinnedOrderA - pinnedOrderB
    if(pinnedOrderA > -1)
      return -1
    if(pinnedOrderB > -1)
      return 1
    if (tokenA.symbol && tokenB.symbol) {
      return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
    }
    return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0
  }
  // const balances = useAllTokenBalances()
  // const comparator = useMemo(() => getTokenComparator(balances ?? {}), [balances])
  // return useMemo(() => {
  //   if (inverted) {
  //     return (tokenA: Token, tokenB: Token) => comparator(tokenA, tokenB) * -1
  //   }
  //   return comparator
  // }, [inverted, comparator])
}

export default useTokenComparator
